
import Vue from 'vue';

import { PictureModel } from '@/models/user.model';
import './PictureList.css';
import firebase from '@/services/firebase';
import Loader from '../Loader/Loader';

export interface PictureListProps {
  handleDelete: (id: string) => void;
  pictures: PictureModel[];
  showActions: boolean;
  actions?: {
    delete: boolean;
    download: boolean;
  };
}

export const ImageLoader: any = Vue.extend({
  data() {
    return {
      imageUrl: '',
      loading: true,
    };
  },

  methods: {
    loadImage() {
      const { imageMap, token, pic } = this.$attrs as any;

      const request = new Request(pic.url);

      fetch(request, {
        cache: 'default',
        headers: {
          authorization: token,
        },
        method: 'GET',
        mode: 'cors',
      })
        .then((response: any) => {
          response.blob()
            .then((blob: any) => {
              this.imageUrl = URL.createObjectURL(blob);
              this.loading = false;

              if (imageMap) {
                imageMap[pic.id] = this.imageUrl;
              }
            });
        })
        .catch((err) => {
          // TODO: Handle error loading image
          console.log('Error loading image => ', err);
        });
    },
  },

  mounted() {
    this.loadImage();
  },

  render() {
    if (this.loading) return <Loader />;

    return (
      <img src={this.imageUrl} alt={this.imageUrl} class={this.$attrs.className} />
    );
  },
});


const PictureList: any = Vue.extend({
  data() {
    return {
      authToken: '',
      imageMap: {},
    };
  },

  methods: {
    getAuthToken() {
      firebase.auth().currentUser?.getIdToken()
        .then((token: string) => {
          this.authToken = token;
        });
    },

    handleDownload(pic: PictureModel) {
      const imageBlobUrl = (this.imageMap as any)[pic.id];

      if (!imageBlobUrl) {
        // TODO: Handle case when this is not present
        return;
      }

      const anchor = document.createElement('a') as HTMLAnchorElement;
      anchor.href = imageBlobUrl;

      const splitUrl = pic.url.split('.');

      anchor.download = `${pic.id}.${splitUrl[splitUrl.length - 1]}`;
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
    },
  },

  mounted() {
    this.getAuthToken();
  },

  render() {
    const props = this.$attrs as unknown as PictureListProps;

    const {
      actions,
      handleDelete,
      showActions,
      pictures,
    } = props;

    if (!Array.isArray(pictures) || pictures.length === 0) {
      return (
        <div class="generic-empty-state">No picture found</div>
      );
    }

    if (!this.authToken) {
      return <Loader />;
    }

    return (
      <ul class="picture-list">
        {
          pictures.map(pic => (
            <li key={pic.id} class="picture-list__item">
              {
                showActions && (
                  <div class="picture-list__item__actions">
                    {
                      actions?.delete && (
                        <a
                          class="picture-list__delete"
                          onClick={(event: Event) => {
                            event.preventDefault();
                            handleDelete(pic.id);
                          }}
                        >
                          <img src={require('@/components/Icons/delete.png')} />
                          <span>Delete</span>
                        </a>

                      )
                    }

                    {
                      actions?.download && (
                        <a
                          class="picture-list__delete"
                          onClick={(event: Event) => {
                            event.preventDefault();
                            this.handleDownload(pic);
                          }}
                        >
                          <img src={require('@/components/Icons/download.png')} />
                          <span>Download</span>
                        </a>
                      )
                    }

                  </div>
                )
              }
              <ImageLoader token={this.authToken} imageMap={this.imageMap} pic={pic} className="picture-list__item__image" />
            </li>
          ))
        }
      </ul>
    );
  },
});

export default PictureList;
